import React, { Component } from "react";
// import Paper from '@mui/material/Paper';
// import {
//   Chart,
//   BarSeries,
//   Title,
//   ArgumentAxis,
//   ValueAxis,
// } from '@devexpress/dx-react-chart-material-ui';
// import {
//   Export,
// } from 'devextreme-react/pie-chart';

// import { Animation } from '@devexpress/dx-react-chart';

// const data = [
//   { year: '1950', population: 2.525 },
//   { year: '1960', population: 3.018 },
//   { year: '1970', population: 3.682 },
//   { year: '1980', population: 4.440 },
//   { year: '1990', population: 5.310 },
//   { year: '2000', population: 6.127 },
//   { year: '2010', population: 6.930 },
// ];
import {
  Chart,
  Series,
  Export,
  CommonSeriesSettings,
  Label,
  Format,
  Tooltip,
} from "devextreme-react/chart";
import { GetControlPropertyFromStoreOrRefData } from "../../../General/commonFunctions";
import { Grid } from "@mui/material";
// import { Title } from "devextreme-react/bar-gauge";

class BarChartComponent extends Component {
  constructor(props) {
    super(props);
    let chartData = {};
    chartData = GetControlPropertyFromStoreOrRefData(
      this.props.data,
      this.props.refData
    );
    this.state = {
      chartData: chartData,
      // chartTitle: props.chartTitle,
      valueField: props.valueField,
      argumentField: props.argField,
      chartType: props.chartType,
    };
  }

  render() {
    // const { data: chartData } = this.state;

    // return (
    //   <Paper style={{ margin: "1px" }}>
    //     {this.props?.chartData ?
    //       <Chart {...this.props}
    //         data={this.state?.data}
    //       >
    //         <ArgumentAxis />
    //         <ValueAxis max={7} />

    //         <BarSeries
    //           valueField={this.state?.valueField}
    //           argumentField={this.state?.argumentField} />
    //         {this.props?.chartTitle ?
    //           <Title text={this.state?.chartTitle} /> :
    //           <></>}
    //         <Animation />
    //         {this.props?.exportEnabled ?
    //           <Export enabled={this.props?.exportEnabled} /> :
    //           <></>}
    //       </Chart> :
    //       <></>
    //     }
    //   </Paper>
    // );
    return (
      <Grid container={true}>
        <Grid item={true} xs={12}>
          {this.state?.chartData ? (
            <Chart
              id="chart"
              {...this.props}
              dataSource={this.state?.chartData}
              palette={this.props?.palette}
            >
              <CommonSeriesSettings
                argumentField={this.state?.argumentField}
                valueField={this.state?.valueField}
                type={this.state?.chartType}
              >
                {this.props?.labelVisible ? (
                  <Label visible={this.props?.labelVisible}>
                    {this.props?.labelPrecision ? (
                      <Format
                        type="fixedPoint"
                        precision={this.props?.labelPrecision}
                      />
                    ) : (
                      <></>
                    )}
                  </Label>
                ) : (
                  <></>
                )}
              </CommonSeriesSettings>

              {this.props.Series?.map((t) => {
                return <Series />;
              })}
              {this.props?.exportEnabled ? (
                <Export enabled={this.props?.exportEnabled} />
              ) : (
                <></>
              )}
              {this.props?.toolTipEnabled ? (
                <Tooltip enabled={this.props?.toolTipEnabled} />
              ) : (
                <></>
              )}
            </Chart>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default BarChartComponent;
